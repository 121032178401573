module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.6_@swc+core@1.5.7_@swc+helpers@0.5.11__babel-es_m3atmdirpjjqy7urqvan4ihswi/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","fallbackLng":"es","siteUrl":"http://localhost:8000/","i18nextOptions":{"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../../../node_modules/.pnpm/@nathanpate+gatsby-omni-font-loader@2.0.5_gatsby@5.13.6_@swc+core@1.5.7_@swc+helpers@0.5.11___5fxejjoy24eqxo4tcgxmqpkkyy/node_modules/@nathanpate/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Noto Sans","file":"https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap"}]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_@swc+core@1.5.7_@swc+helpers@0.5.11__babel-eslint_tdywu2z4bgz23d6ktxgwwquvji/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Drify.io","short_name":"Drify","icon":"src/images/logo.svg","icon_options":{"purpose":"any maskable"},"start_url":"/","lang":"es","background_color":"#4F378B","theme_color":"#4F378B","display":"standalone","localize":[{"start_url":"/en/","lang":"en","name":"Drify.io","short_name":"Drify"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ac7e913ef6bb1dcc8eb6b549cd386234"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.6_@swc+core@1.5.7_@swc+helpers@0.5.11__babel-eslint@10.1.0_eslint@7.32.0__esbuild_6326jmgarkuxkawqtbduuttfmy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
